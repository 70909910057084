import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { Helmet } from "react-helmet";
import BurgerMenu from "../../components/BurgerMenu";
import UpArrow from "../../svg/dropdown-arrow.svg";
import { Deprecated } from "../../components/PageElements/Elements/Deprecated";
import { navigate } from "gatsby";
import {scrollToSection} from '../../helpers/utils.js'
import CopyCode from "../../components/Section/CopyCode";
import {
  Wrapper as ResponseWrapper,
  Container as ResponseContainer
} from "../../components/Section/Responses";

import {
  Title,
  SubTitle,
  Summary,
  Paragraph
} from "../../components/PageElements/StaticElements";

import Sms from "../../svg/SMS.js";

import LinkParagraph from "../../components/PageElements/StaticElements/InnerHtmlParagraph";
import Important from "../../components/PageElements/StaticElements/Important";
import StaticTable from "../../components/PageElements/StaticElements/StaticTable";
import BaseUrl from "../../components/PageElements/StaticElements/BaseUrl";

const sections = [
  "Organisation setup",
  "Authentication",
  "Sending SMS message",
  "Delivery report",
  "Organisation balance",
  "Creating sub-organisation",
  "Transferring credits from one organisation to another",
  "Adding contacts",
  "Deleting SMS",
  "Sent SMS message list",
  "Checking SMS message status",
  "Delivery report callback",
  "Sending SMS message with allowed reply and reply notification",
  "Retrieving SMS replies",
  "Sending Text-To-Speech SMS",
  "Sending Unicode SMS",
  "Sending SMS in batch mode (attached file)",
  "Summary list of statuses of returned SMS"
];

const OverrideResponseWrapper = styled(ResponseWrapper)`
  height: unset;
  max-width: 850px;
  margin: 20px 0;
`;

const ApiHttp = () => {
  const [displayBurger, setDisplayBurger] = useState(false);

  const onScroll = () => {
    const target = document.getElementById("back2TopButton");
    if (target && window.pageYOffset > 850) target.style.display = "block";
    else if (target) target.style.display = "none";
  };

  useEffect(() => {
    const timerColor = setTimeout(() => {
      document.getElementById("sbc_color").style.display = "block";
    }, 1500);
    const timerStroke = setTimeout(() => {
      document.getElementById("sbc_stroke").style.display = "block";
    }, 1000);
    return () => {
      clearTimeout(timerColor);
      clearTimeout(timerStroke);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <Wrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>smsmode©| HTTP API Reference</title>
      </Helmet>
      <LeftContainer>
        <Summary>
          {sections.map((section, index) => (
            <button
              key={section}
              type="button"
              onClick={() => scrollToSection(section, 0)}
            >
              <p>{section}</p>
              <span>{index + 1}</span>
            </button>
          ))}
        </Summary>
      </LeftContainer>
      <BurgerMenu
        pdf
        color="#52b474"
        onClick={() => setDisplayBurger(!displayBurger)}
        display={displayBurger}
        setDisplay={setDisplayBurger}
      >
        <Summary>
          {sections.map((section, index) => (
            <button
              key={section}
              type="button"
              onClick={() => {
                setDisplayBurger(false);
                scrollToTop(section, -100);
              }}
            >
              <p>{section}</p>
              <span>{index + 1}</span>
            </button>
          ))}
        </Summary>
      </BurgerMenu>
      <RightContainer>
        <Section style={{ marginTop: "65px" }} id={sections[0]}>
          <TitleContainer>
            {/* <MovingWave className="WaveWrapper" color="#52b4743d" invert /> */}
            <SvgWrapper>
              <Sms />
            </SvgWrapper>
            <h1>{`HTTP API`}</h1>
            <h2>{"Easily and automatically send SMS messages"}</h2>
          </TitleContainer>
          <ButtonSnippets onClick={() => navigate("/http-api/snippets")}>
            CODE SNIPPETS
          </ButtonSnippets>
          <Title>
            <Number>1</Number>
            {sections[0]}
          </Title>
          <Paragraph>
            Depending on the nature of the SMS communication, text messages can
            be sent via two distinct channels:{" "}
            <span>SMS Marketing channel</span> and{" "}
            <span>SMS Notification channel</span>. By default and without
            specific request on your side, all campaigns are executed via the
            SMS marketing channel (see below for detailed description and
            limitations). Please contact our sales team (+33 4 91 05 64 62) for
            additional information and channel setup.
          </Paragraph>
          <Important
            text="smsmode<sup>©</sup> cannot be held responsible for the eventual blocking or unsolicited message
                  sender ID modification enforced by the French operators due to incorrect channel setup."
          />
          <SubTitle>SMS MARKETING CHANNEL</SubTitle>
          <Paragraph>
            The SMS Marketing channel may be used for any type of communication
            (commercial or else). In case of sender ID customisation, the “STOP”
            mention must be present in the text of the message. Without “STOP
            number”, the SMS may either be blocked by the operators (especially
            Free), or the sender ID may be altered and replaced by a shortcode.
          </Paragraph>
          <Important
            text="The STOP mention follows the following format: text string “STOP” followed by a 5-digit shortcode
            (36034, for example). The use of the STOP parameter ensures you that the rules of use
            imposed by the operators and the French administrative authority CNIL (the CNIL regulation states that it
            is obligatory to propose a solution to unsubscribe from any commercial campaign)."
          />
          <Paragraph>
            If the sender ID has not been customized, the use of the “STOP
            number” mention is not necessary, but remains strongly advised. The
            routing of SMS via the Marketing channel benefits of a lower
            priority than the Notification channel. Potential delivery delays
            may be expected
          </Paragraph>
          <SubTitle>SMS NOTIFICATION CHANNEL</SubTitle>
          <Paragraph>
            The Notification channel is used for any type of non-commercial
            communication. The sender ID may be customized. The use of the “STOP
            number” mention is not necessary. Any SMS communication of
            commercial nature via the Notification channel will be blocked by
            the operators, and may be subjected to financial penalties (please
            see our generals terms of use for details). Routing is done with
            highest priority and highest performance.
          </Paragraph>
        </Section>
        <Section id={sections[1]}>
          <Title>
            <Number>2</Number>
            {sections[1]}
          </Title>
          <Paragraph>
            The HTTP interface provides as an <span>authentication token</span>.
            This solution also allows you to be in good standing with the RGPD.
            The API key is to be generated either by a call to the API key
            creation request or directly to its personal smsmode<sup>©</sup>{" "}
            channel.
          </Paragraph>
          <SubTitle>
            {`METHOD 1: CREATING AN API KEY VIA THE SMSMODE© USER INTERFACE`}
          </SubTitle>
          <LinkParagraph>
            {`<a href='https://ui.smsmode.com/settings/keys' target='_blank' rel='noopener noreferrer'>Go to the “Settings” > “Access key API” to manage the API keys</a>`}
          </LinkParagraph>
          <Important text="This method is required when creating the first API key of a channel." />
          <SubTitle>METHOD 2: CREATING A KEY API VIA A REQUEST</SubTitle>
          <BaseUrl>
            https://api.smsmode.com/http/2.0/createAuthorisation.do
          </BaseUrl>
          <OverrideResponseWrapper>
            <ResponseContainer>
              <CopyCode label="Request example">
                https://api.smsmode.com/http/2.0/createAuthorisation.do?accessToken=Ab1CD2efg3Hi
              </CopyCode>
            </ResponseContainer>
          </OverrideResponseWrapper>
          <StaticTable
            title="AUTHENTICATION PARAMETER"
            data={[
              {
                accessToken:
                  "Access key to be used to connect to your smsmode<sup>©</sup> channel."
              }
            ]}
          />
          <StaticTable
            title="RESPONSE (JSON FORMAT)"
            data={[
              { id: "Token identification" },
              {
                accessToken: "32 character chain to be used as authentication"
              },
              { creationDate: "Creation date" },
              { state: "Channel status" },
              { expiration: "Expiry date" },
              { account: "Channel name related to the Token" },
              {
                relatedAccountName:
                  "(possible but not mandatory) fill in the name of the channel on which you want create the API key"
              }
            ]}
          />
        </Section>
        <Section id={sections[2]}>
          <Title>
            <Number>3</Number>
            {sections[2]}
          </Title>
          <Paragraph>
            The HTTP API allows you to automatize SMS message sending to one or
            more recipients. The SMS messages can be sent immediately or
            scheduled for later. Both HTTP and HTTPS protocols are supported,
            but for security reasons it is highly recommended to use{" "}
            <span>HTTPS</span>. HTTP <span>GET</span> and{" "}
            <span>POST methods</span> are supported but only the POST method
            allows to send SMS messages to more than 300 recipients using only
            one request.
          </Paragraph>
          <BaseUrl>https://api.smsmode.com/http/1.6/sendSMS.do</BaseUrl>
          <OverrideResponseWrapper>
            <ResponseContainer>
              <CopyCode label="Request example">
                {`https://api.smsmode.com/http/1.6/sendSMS.do?accessToken=Ab1CD2efg3Hi&message=Hello+Mum&numero=336xxxxxxxx,06yyyyyyyy\n\nhttps://api.smsmode.com/http/1.6/sendSMS.do?accessToken=Ab1CD2efg3Hi&message=Happy+birthday&numero=336xxxxxxxx&date_envoi=21122005-14:35`}
              </CopyCode>
            </ResponseContainer>
          </OverrideResponseWrapper>
          <StaticTable
            title="AUTHENTICATION PARAMETER"
            data={[
              {
                accessToken:
                  "Access key to be used to connect to your smsmode<sup>©</sup> channel"
              }
            ]}
          />
          <StaticTable
            title="MANDATORY PARAMETERS"
            data={[
              {
                message: `SMS text. Maximum text length for 1 SMS is 160 characters.
                  If you need to send long SMS (more than 160 characters) please refer to optional
                  parameters below.`
              },
              {
                "numero **": `Comma separated MSISDN list. These numbers may use national format (06xxxxxxxx)
                  only for French MSISDN) or international format (336xxxxxxxx).
                  When using HTTP GET list size is limited to 300.`
              },
              {
                "groupe **": `Name of the group of contact which contains the recipients’ MSISDNs. Note that the group
                must be present (previously created) on your smsmode<sup>©</sup> organisation.`
              }
            ]}
          />
          <Important text="** Use only one of the two parameters"></Important>
          <StaticTable
            title="OPTIONAL PARAMETERS"
            data={[
              {
                classe_msg: `• 2 : SMS Pro (default value)</br>• 4 : SMS message allowing an answer from the recipient (only available in France)`
              },
              {
                date_envoi: `SMS sending date (format : ddMMyyyy-HH:mm)`
              },
              {
                refClient: `Optional reference ID that will be sent along with the delivery report callback. May be used
                to set a foreign ID (coming from your system) on this SMS message.
                Maximum size is 140 characters.`
              },
              {
                emetteur: `Text to be used to customize the SMS sender ID.
                MSISDN are not allowed.
                Maximum size is 11 characters without blank space or accent. This function is not
                available when using classe_msg=4.
                Sender ID available according to the technical constraints of the countries.`
              },
              {
                nbr_msg: `Max number of SMS messages that can be sent (default to 5)
                The message will be truncated if it exceeds this limit. Depending on the desired
                characters count, indicate the number of message to be used as following: <br/>
                nbr_msg=1 : 160 characters max<br/>
                nbr_msg=2 : 306 characters max<br/>
                nbr_msg=3 : 459 characters max`
              },
              {
                notification_url: `Callback URL to be used to notify your system when delivery report is updated.`
              },
              {
                notification_url_reponse: `Callback URL to be used to notify your system when incoming message arrives.`
              }
            ]}
          />
          <StaticTable
            title="RESPONSE"
            desc="Response to the request will follow this pattern: status_code | description | smsID (only if status_code = 0)."
            customColumn1="STATUS CODE"
            data={[
              {
                0: `Accepted – message is accepted by the system and is currently being processed.`
              },
              {
                31: `Internal error`
              },
              {
                32: `Authentication error`
              },
              {
                33: `Insufficient organisation balance`
              },
              {
                35: `Missing mandatory parameter`
              },
              {
                42: `Unauthorized ip address`
              },
              {
                50: `Temporarily unavailable`
              }
            ]}
          />
          <SubTitle id="CHARACTER ENCODING">CHARACTER ENCODING</SubTitle>
          <Paragraph>
            Only a restricted character set can be used when sending SMS
            messages (GSM 03.38 requirements). If you try to use a character
            that is not in the set, the character will be replaced by ‘?’. We
            use ISO-8859-15 character encoding. Please find below the list of
            accepted characters and their ISO-8859-15 value.
          </Paragraph>
          <CharacterEncodingContainer>
            <StaticTable
              borderRight
              customColumn1="CHARACTERS"
              customColumn2="CODE ISO 8859-15"
              data={[
                { A: "A" },
                { B: "B" },
                { C: "C" },
                { D: "D" },
                { E: "E" },
                { F: "F" },
                { G: "G" },
                { H: "H" },
                { I: "I" },
                { J: "J" },
                { K: "K" },
                { L: "L" },
                { M: "M" },
                { N: "N" },
                { O: "O" },
                { P: "P" },
                { Q: "Q" },
                { R: "R" },
                { S: "S" },
                { T: "T" },
                { U: "U" },
                { V: "V" },
                { W: "W" },
                { X: "X" },
                { Y: "Y" },
                { Z: "Z" },
                { _: "_" },
                { a: "a" },
                { b: "b" },
                { c: "c" },
                { d: "d" },
                { e: "e" },
                { f: "f" },
                { g: "g" },
                { h: "h" },
                { i: "i" },
                { j: "j" },
                { k: "k" },
                { l: "l" },
                { m: "m" },
                { n: "n" },
                { o: "o" },
                { p: "p" }
              ]}
            />
            <StaticTable
              borderRight
              customColumn1="CHARACTERS"
              customColumn2="CODE ISO 8859-15"
              data={[
                { q: "q" },
                { r: "r" },
                { s: "s" },
                { t: "t" },
                { u: "u" },
                { v: "v" },
                { w: "w" },
                { x: "x" },
                { y: "y" },
                { z: "z" },
                { "¡": "%A1" },
                { "£": "%A3" },
                { "€": "%A4" },
                { "¥": "%A5" },
                { "§": "%A7" },
                { "¿": "%BF" },
                { Ä: "%C4" },
                { Å: "%C5" },
                { Æ: "%C6" },
                { Ç: "%C7" },
                { È: "%C8" },
                { É: "%C9" },
                { Ñ: "%D1" },
                { Ö: "%D6" },
                { Ø: "%D8" },
                { Ü: "%DC" },
                { ß: "%DF" },
                { à: "%E0" },
                { ä: "%E4" },
                { å: "%E5" },
                { æ: "%E6" },
                { è: "%E8" },
                { é: "%E9" },
                { ì: "%EC" },
                { ñ: "%F1" },
                { ò: "%F2" },
                { ö: "%F6" },
                { ø: "%F8" },
                { ù: "%F9" },
                { ü: "%FC" },
                { "@": "%40" },
                { "€": "%80 **" }
              ]}
            />
            <StaticTable
              customColumn1="CHARACTERS"
              customColumn2="CODE ISO 8859-15"
              data={[
                { "New line": `%0A` },
                { "Carriage return": "%0D" },
                { "Blank space": "+" },
                { "!": "%21" },
                { '"': "%22" },
                { "#": "%23" },
                { $: "%24" },
                { "%": "%25" },
                { "&": "%26" },
                { "`": "%27" },
                { "(": "%28" },
                { ")": "%29" },
                { "*": "*" },
                { "+": "%2B" },
                { ",": "%2C" },
                { "-": "-" },
                { ".": "." },
                { "/": "%2F" },
                { "{": "%7B **" },
                { "|": "%7C **" },
                { "}": "%7D **" },
                { "~": "%7E **" },
                { "[": "%5B **" },
                // { "\\": "%5C **" },
                { "]": "%5D **" },
                { "^": "%5E **" },
                { 0: "0" },
                { 1: "1" },
                { 2: "2" },
                { 3: "3" },
                { 4: "4" },
                { 5: "5" },
                { 6: "6" },
                { 7: "7" },
                { 8: "8" },
                { 9: "9" },
                { ":": "%3A" },
                { ";": "%3B" },
                { "<": "%3C" },
                { "=": "%3D" },
                { ">": "%3E" },
                { "?": "%3E" }
              ]}
            />
          </CharacterEncodingContainer>
          <p style={{ fontStyle: "italic" }}>
            ** Caution: these characters count double.
          </p>
          <SubTitle>STOP SMS PARAMETER</SubTitle>
          <Paragraph>
            STOP SMS quote gives the recipient the opportunity to easily opt-out
            from marketing campaigns. This feature is mandatory by law in some
            countries like France. By default, no STOP SMS quote will be added
            to the message when sending an SMS message via the API. You will
            need to use the stop parameter if you wish to append a STOP SMS
            quote to your message.
          </Paragraph>
          <Important
            text="STOP SMS quote is a string with the format “STOP XXXXX” for France (where XXXXX is a 5-digit
                  shortcode, in use it may vary - depending on the destination, operator, etc. to which the SMS is being
                  addressed to -) or with the mention “no-sms.eu” for all messages sent to international countries. Using
                  the stop parameter will enable the system to automatically set it and thus ensure that the right STOP SMS
                  quote will always be used."
          />
          <Important
            text="in France, a unsubscription confirmation message is mandatory. A credit will be debited automatically
                  from your organisation for each unsubscription."
          />
          <OverrideResponseWrapper>
            <ResponseContainer>
              <CopyCode label="Request example">
                https://api.smsmode.com/http/1.6/sendSMS.do?accessToken=Ab1CD2efg3Hi&message=Hello+Mum
                &numero=336xxxxxxxx,06yyyyyyyy&stop=1
              </CopyCode>
            </ResponseContainer>
          </OverrideResponseWrapper>
          <StaticTable
            title="Parameter value"
            desc="In order to avoid unwanted SMS overconsumption, this parameter accepts two values:"
            data={[
              {
                "stop=1": `STOP quote added only if it doesn’t increase the SMS cost.<br/>
                • I f original text length to send is less than or equal to 149 characters, the STOP quote will
                be added.<br/>
                • I f original text length is greater than or equal to 150 characters, the STOP quote won’t
                be added (in order to avoid the text length to be accidentally greater than 160 character,
                which would require the consumption of 2 SMS messages when sent).
                This way, you keep control of your SMS consumption.`
              },
              {
                "stop=2": `STOP quote added regardless of the SMS message length.<br/>
                • I f original text length to send is less than or equal to 149 characters, the STOP quote will
                be added and it will only cost 1 SMS.<br/>
                • I f original text length is greater than or equal to 150 characters, the STOP quote will also
                be added. The text size will be greater than 160 characters and the sending cost will be
                of 2 SMS.
                You are assured that the STOP mention will be present in your message but you can observe
                an overconsumption of SMS`
              }
            ]}
          />
        </Section>
        <Section id={sections[3]}>
          <Title>
            <Number>4</Number>
            {sections[3]}
          </Title>
          <Paragraph>
            The HTTP API allows you to retrieve delivery reports of an SMS
            message that was sent.
          </Paragraph>
          <Important text="Search limit: The search is limited to SMS messages sent within the last 30 days."></Important>
          <Important text="Reports are received on average a few seconds after sending the SMS; however, this delay can extend up to 48 hours depending on the operators and the load of our platform."></Important>
          <BaseUrl>https://api.smsmode.com/http/1.6/compteRendu.do</BaseUrl>
          <OverrideResponseWrapper>
            <ResponseContainer>
              <CopyCode label="Request example">
                {`https://api.smsmode.com/http/1.6/compteRendu.do?accessToken=Ab1CD2efg3Hi&smsID=12345678`}
              </CopyCode>
            </ResponseContainer>
          </OverrideResponseWrapper>
          <StaticTable
            title="AUTHENTICATION PARAMETER"
            data={[
              {
                accessToken:
                  "Access key to be used to connect to your smsmode<sup>©</sup> channel"
              }
            ]}
          />
          <StaticTable
            title="MANDATORY PARAMETER"
            data={[
              {
                smsID: `ID number of the SMS message for which you want to retrieve the delivery reports. The ID
                is included in the response returned by the server for an SMS message sent via the HTTP
                API.`
              }
            ]}
          />
          <StaticTable
            title="OPTIONAL PARAMETERS"
            data={[
              {
                offset: `Number of SMS messages to fetch (default set to 50)`,
                start: `Index of the first SMS message of the list (default set to 0)`
              }
            ]}
          />
          <StaticTable
            title="RESPONSE"
            customColumn1="STATUS CODE"
            data={[
              {
                31: `Internal error`
              },
              { 35: `Missing mandatory parameter` },
              {
                42: `Unauthorized ip address`
              },
              { 61: `SMS not found` }
            ]}
          />
          <Important text="Status Response to the request will follow this pattern: recipient_msisdn status | recipient_msisdn status | ..." />
          <OverrideResponseWrapper>
            <ResponseContainer>
              <CopyCode label="Response example">
                {`+33600000000 11 | +33611111111 13`}
              </CopyCode>
            </ResponseContainer>
          </OverrideResponseWrapper>
          <Paragraph>
            In this example, SMS message has been received by MSISDN 0600000000,
            and was delivered to provider for MSISDN 0611111111 (in the case
            when a recipient’s phone is turned off or outside of network).
          </Paragraph>
          <StaticTable
            title="RESPONSE"
            customColumn1="STATUS CODE"
            data={[
              { 0: `Sent` },
              { 2: `Internal error` },
              { 11: `Received` },
              { 13: `Gateway delivered` },
              { 34: `Gateway delivered` },
              {
                35: `Delivery error (SMS message not delivered by provider to recipient’s phone)`
              },
              {
                42: `Unauthorized ip address`
              }
            ]}
          />
        </Section>
        <Section id={sections[4]}>
          <Title>
            <Number>5</Number>
            {sections[4]}
          </Title>
          <Paragraph>
            The HTTP API allows you to check your organisation balance by
            getting the remaining amount of credit for your organisation.
          </Paragraph>
          <Important
            text="Please note: if your organisation is in post-pay mode, you will get a
            negative value."
          ></Important>
          <BaseUrl>https://api.smsmode.com/http/1.6/credit.do</BaseUrl>
          <OverrideResponseWrapper>
            <ResponseContainer>
              <CopyCode label="Request example">
                https://api.smsmode.com/http/1.6/credit.do?accessToken=Ab1CD2efg3Hi
              </CopyCode>
            </ResponseContainer>
          </OverrideResponseWrapper>
          <StaticTable
            title="AUTHENTICATION PARAMETER"
            data={[
              {
                accessToken: `Access key to be used to connect to your smsmode<sup>©</sup> channel.`
              }
            ]}
          />
          <SubTitle>RESPONSE</SubTitle>
          <Paragraph>
            Response to an organisation balance request is a unique figure
            representing your organisation balance.
          </Paragraph>
          <OverrideResponseWrapper>
            <ResponseContainer>
              <CopyCode label="Response example">212.5</CopyCode>
            </ResponseContainer>
          </OverrideResponseWrapper>
        </Section>
        <Section id={sections[5]}>
          <Title>
            <Number>6</Number>
            {sections[5]}
          </Title>
          <Paragraph>
            The HTTP API allows you to create sub-organisations of an already
            existing organisation. You only need to specify the new usernames
            and related passwords.
          </Paragraph>
          <BaseUrl>
            https://api.smsmode.com/http/1.6/createSubAccount.do
          </BaseUrl>
          <OverrideResponseWrapper>
            <ResponseContainer>
              <CopyCode label="Request example">
                https://api.smsmode.com/http/1.6/createSubAccount.do?accessToken=Ab1CD2efg3Hi&newPseudo=bbbbbb&newPass=yyyyyy
              </CopyCode>
            </ResponseContainer>
          </OverrideResponseWrapper>
          <StaticTable
            title="AUTHENTICATION PARAMETER"
            data={[
              {
                accessToken: `Access key to be used to connect to your smsmode<sup>©</sup> channel.`
              }
            ]}
          />
          <StaticTable
            title="MANDATORY PARAMETERS"
            data={[
              {
                newPseudo: `New organisation login Maximum length: 50 characters`
              },
              { newPass: `New organisation password` }
            ]}
          />
          <StaticTable
            title="OPTIONAL PARAMETERS"
            data={[
              {
                reference: `Transaction reference`,
                nom: `Last name of the new organisation contact`,
                prenom: `First name of the new organisation contact`,
                societe: `Company of the new organisation contact`,
                adresse: `Address of the new organisation contact`,
                ville: `City of the new organisation contact`,
                codePostal: `Zip code of the new organisation contact`,
                mobile: `Mobile phone number of the new organisation contact`,
                telephone: `Landline phone number of the new organisation contact`,
                fax: `Fax number of the new organisation contact`,
                email: `Email of the new organisation contact`,
                date: `Date of birth of the new organisation contact`
              }
            ]}
          />
          <StaticTable
            title="RESPONSE"
            customColumn1="STATUS CODE"
            data={[
              {
                0: `Sub-organisation created`,
                31: `Internal error`,
                32: `Authentication error`,
                35: `Invalid parameters`,
                41: `New login already used`,
                42: `Unauthorized ip address`
              }
            ]}
          />
        </Section>
        <Section id={sections[6]}>
          <Title>
            <Number>7</Number>
            {sections[6]}
          </Title>
          <Paragraph>
            The HTTP interface allows user to transfer specific amounts of SMS
            credit from one organisation to another, but also between
            sub-organisations and parent organisations..
          </Paragraph>
          <BaseUrl>https://api.smsmode.com/http/1.6/creditTransfert.do</BaseUrl>
          <OverrideResponseWrapper>
            <ResponseContainer>
              <CopyCode label="Request example">
                {`https://api.smsmode.com/http/1.6/creditTransfert.do?accessToken=Ab1CD2efg3Hi&targetPseudo=bbbbb&creditAmount=12&reference=blablabla`}
              </CopyCode>
            </ResponseContainer>
          </OverrideResponseWrapper>
          <StaticTable
            title="AUTHENTICATION PARAMETER"
            data={[
              {
                accessToken: `Access key to be used to connect to your smsmode<sup>©</sup> channel`
              }
            ]}
          />
          <StaticTable
            title="MANDATORY PARAMETERS"
            data={[
              {
                targetPseudo: `Target organisation login`,
                creditAmount: `Credit amount to transfer Only round figures value are accepted`
              }
            ]}
          />
          <StaticTable
            title="OPTIONAL PARAMETER"
            data={[
              {
                reference: `Transaction reference`
              }
            ]}
          />
          <StaticTable
            title="RESPONSE"
            customColumn1="STATUS CODE"
            data={[
              {
                0: `Transfer done`,
                31: `Internal error`,
                32: `Authentication error`,
                33: `Insufficient organisation balance`,
                35: `Invalid parameters`,
                41: `Target login doesn’t exist`,
                42: `Unauthorized ip address`
              }
            ]}
          />
        </Section>
        <Section id={sections[7]}>
          <Title>
            <Number>8</Number>
            {sections[7]}
          </Title>
          <Paragraph>
            The HTTP interface allows you to automatically add a contact to the
            address book. It also allows you to create a new contacts group if
            the one specified doesn’t already exist.
          </Paragraph>
          <BaseUrl>https://api.smsmode.com/http/1.6/addContact.do</BaseUrl>

          <OverrideResponseWrapper>
            <ResponseContainer>
              <CopyCode label="Request example">
                {`https://api.smsmode.com/http/1.6/addContact.do?accessToken=Ab1CD2efg3Hi&nom=Dupond&prenom=Jacques&mobile=06xxxxxxxxxx&groupes=Groupe1,Groupe2`}
              </CopyCode>
            </ResponseContainer>
          </OverrideResponseWrapper>
          <StaticTable
            title="AUTHENTICATION PARAMETER"
            data={[
              {
                accessToken: `Access key to be used to connect to your smsmode<sup>©</sup> channel`
              }
            ]}
          />
          <StaticTable
            title="MANDATORY PARAMETERS"
            data={[
              {
                nom: `New contact name`,
                mobile: `New contact mobile phone number`
              }
            ]}
          />
          <StaticTable
            title="OPTIONAL PARAMETERS"
            data={[
              {
                prenom: `New contact first name`,
                groupes: `List of group names to which the new contact must be added. If one of the specified group doesn’t exist, it will be automatically created.`,
                societe: `New contact company name`,
                other: `New contact other information`,
                date: `Date of birth of the new organisation contact`
              }
            ]}
          />
          <StaticTable
            title="RESPONSE"
            customColumn1="STATUS CODE"
            data={[
              {
                0: `Contact created`,
                31: `Internal error`,
                42: `Unauthorized ip address`
              }
            ]}
          />
          <Important
            text="Response to the request will follow this pattern: status_code |
          description"
          />
        </Section>
        <Section id={sections[8]}>
          <Title>
            <Number>9</Number>
            {sections[8]}
          </Title>
          <Paragraph>
            The HTTP API allows you to delete a <span>scheduled</span> SMS
            message.
          </Paragraph>
          <BaseUrl>https://api.smsmode.com/http/1.6/deleteSMS.do</BaseUrl>

          <OverrideResponseWrapper>
            <ResponseContainer>
              <CopyCode label="Request example">
                {`https://api.smsmode.com/http/1.6/deleteSMS.do?accessToken=Ab1CD2efg3Hi&smsID=12345678`}
              </CopyCode>
            </ResponseContainer>
          </OverrideResponseWrapper>
          <StaticTable
            title="AUTHENTICATION PARAMETER"
            data={[
              {
                accessToken: `Access key to be used to connect to your smsmode<sup>©</sup> channel`
              }
            ]}
          />
          <StaticTable
            title="MANDATORY PARAMETERS"
            data={[
              {
                "smsID **": `ID of the SMS message to be deleted. The ID is provided within the response given by the server after a HTTP request has been sent.`
              },
              {
                "numero **": `One of the MSISDN recipients present in the SMS message to be deleted. Only the latest SMS message matching criteria will be deleted.`
              }
            ]}
          />
          <Important text="** Use only one of the two parameters"></Important>
          <StaticTable
            title="RESPONSE"
            customColumn1="STATUS CODE"
            data={[
              {
                0: `SMS message deleted`,
                31: `Internal error`,
                32: `Authentication error`,
                35: `Invalid parameters (“accessToken”, “numero” or “smsID” are mandatory)`,
                42: `Unauthorized ip address`,
                65: `SMS message not found`
              }
            ]}
          />
        </Section>
        <Section id={sections[9]}>
          <Title>
            <Number>10</Number>
            {sections[9]}
          </Title>
          <Paragraph>
            The HTTP API allows you to fetch the list of sent SMS messages.
          </Paragraph>
          <BaseUrl>https://api.smsmode.com/http/1.6/smsList.do</BaseUrl>
          <OverrideResponseWrapper>
            <ResponseContainer>
              <CopyCode label="Request example">
                {`https://api.smsmode.com/http/1.6/smsList.do?accessToken=Ab1CD2efg3Hi&offset=2`}
              </CopyCode>
            </ResponseContainer>
          </OverrideResponseWrapper>
          <StaticTable
            title="AUTHENTICATION PARAMETER"
            data={[
              {
                accessToken: `Access key to be used to connect to your smsmode<sup>©</sup> channel`
              }
            ]}
          />
          <StaticTable
            title="OPTIONAL PARAMETERS"
            data={[
              {
                offset: `Number of SMS messages to fetch (default set to 50)`,
                start: `Index of the first SMS message of the list (default set to 0)`
              }
            ]}
          />
          <StaticTable
            title="RESPONSE"
            customColumn1="STATUS CODE"
            data={[
              {
                31: `Internal error`,
                32: `Authentication error`,
                35: `Invalid parameter (“accessToken” parameters is mandatory)`,
                42: `Unauthorized ip address`
              }
            ]}
          />
          <Important text="Response to the HTTP request will follow this pattern: smsId | sent_date | SMS_Message_text | Recipient_MSISDN | cost_in_credit | recipient_count |"></Important>
        </Section>
        <Section id={sections[10]}>
          <Title>
            <Number>11</Number>
            {sections[10]}
          </Title>
          <Paragraph>
            The HTTP API allows you to check the current status of a sent SMS
            message.
          </Paragraph>
          <Important
            text="Search limit: Search is limited to SMS messages sent in the last 30 days. If an SMS message has been
                    sent to more than one recipient, then the status will be the campaign status. If you want to check details
                by recipient you need to check the delivery report."
          ></Important>
          <BaseUrl>https://api.smsmode.com/http/1.6/smsStatus.do</BaseUrl>
          <OverrideResponseWrapper>
            <ResponseContainer>
              <CopyCode label="Request example">
                {`https://api.smsmode.com/http/1.6/smsStatus.do?accessToken=Ab1CD2efg3Hi&smsID=12345678`}
              </CopyCode>
            </ResponseContainer>
          </OverrideResponseWrapper>
          <StaticTable
            title="AUTHENTICATION PARAMETER"
            data={[
              {
                accessToken: `Access key to be used to connect to your smsmode<sup>©</sup> channel`
              }
            ]}
          />
          <StaticTable
            title="MANDATORY PARAMETER"
            data={[
              {
                smsID: `SMS message ID for which you wish to know the status. The ID is provided in the response issued by the server once a HTTP request has been sent`
              }
            ]}
          />
          <StaticTable
            title="RESPONSE"
            customColumn1="STATUS CODE"
            data={[
              {
                0: `Sent`,
                2: `Internal error – not sent`,
                10: `Scheduled`,
                11: `Received`,
                13: `Delivered`,
                32: `Authentication error`,
                35: `Invalid parameters (“accessToken” and “smsID” are mandatory parameters)`,
                42: `Unauthorized ip address`,
                61: `Invalid smsID`,
                39: `Delivery error`,
                3501: `Temporary operator error`,
                3502: `Temporary absent subscriber error`,
                3503: `Temporary phone error`,
                3521: `Permanent operator error`,
                3522: `Permanent absent subscriber error`,
                3523: `Permanent phone error`,
                3524: `Spam filter error`,
                3526: `Content permanent error / Portability permanent error`,
                3527: `Roaming permanent error`,
                3599: `Undefined error`,
                3998: `Invalid number`,
                3999: `Recipient blacklisted`
              }
            ]}
          />
          <Important text="Response to a HTTP request will follow this pattern: status | description"></Important>
          <OverrideResponseWrapper>
            <ResponseContainer>
              <CopyCode label="Response example">{`0 | Sent`}</CopyCode>
            </ResponseContainer>
          </OverrideResponseWrapper>
        </Section>
        <Section id={sections[11]}>
          <Title>
            <Number>12</Number>
            {sections[11]}
          </Title>
          <Paragraph>
            The HTTP API allows you to be automatically notified when delivery
            reports are updated (for example from “gateway delivered” to
            ‘received’). To be notified you must set the “notification_url”
            parameter when you send an SMS message.
          </Paragraph>

          <OverrideResponseWrapper>
            <ResponseContainer>
              <CopyCode label="Request example">
                {`https://api.smsmode.com/http/1.6/sendSMS.do?accessToken=Ab1CD2efg3Hi&message=Hello+test
            &numero=336xxxxxxxx&notification_url=http://www.yourNotificationURL.com/DeliveryReport
            Notification.php&refClient=ref123`}
              </CopyCode>
            </ResponseContainer>
          </OverrideResponseWrapper>
          <StaticTable
            title="RESPONSE"
            customColumn1="RETURN CODE"
            data={[
              {
                0: `Accepted - the message has been accepted by the system and is being processed`,
                31: `Internal error`,
                32: `Authentication error`,
                33: `Insufficient credit`,
                35: `Mandatory parameters missing`,
                42: `Unauthorized ip address`,
                50: `Temporarily unaccessible`
              }
            ]}
          />
          <Important text="The answer to a HTTP sending request is as follows: code_retour | description | campagneID (present if return code 0)."></Important>
          <StaticTable
            title="PARAMETERS RETURNED BY SMSMODE <sup>©</sup>"
            data={[
              {
                numero: `Recipient MSISDN (international format of type 336xxxxxxxx)`,
                date_reception: `Status report delivery date (date format is yyyy-MM-dd HH:mm:ss)`,
                statut: `Cf. status list`,
                smsID: `SMS Message ID`,
                refClient: `Optional reference ID if it has been set when the SMS message has been sent`,
                cost: `Cost of the SMS sending in credit`,
                mcc_mnc: `Operator code (format to GSM standard)`
              }
            ]}
          />
          <OverrideResponseWrapper>
            <ResponseContainer>
              <CopyCode label="Request example">
                {`http://www.votreURLdeNotificationdeCR.com/compteRenduNotification.php?numero=336xxxxxxxx&
            date_reception=2010-03-25+09%3A52%3A17&statut=11&smsID=12345678&refClient=ref123&
            mcc_mnc=20801&cost=1`}
              </CopyCode>
            </ResponseContainer>
          </OverrideResponseWrapper>
          <Important
            text="The smsmode platform offers two notification formats, GET (deprecated) or POST request.<br/>
          Please contact the <a href='https://ticket.smsmode.com/tickets-view' target='_blank' rel='noopener noreferrer' >support</a> to set up your account to the desired format.<br/>
          Here the <a href='https://dev.smsmode.com/sms/v1/callback' target='_blank' rel='noopener noreferrer'>link</a> to the documentation for the POST format."
          ></Important>
        </Section>
        <Section id={sections[12]}>
          <Title>
            <Number>13</Number>
            {sections[12]}
          </Title>
          <LinkParagraph>
            {`The HTTP API allows you to send SMS message to which the recipient
            may reply. For this you need to use the proper “classe_msg” value
            when sending the SMS message. The SMS message sender ID
            will be a 5-digit number to which the recipient may reply. Replies
            will be saved to your channel. You can see the replies <a href='https://ui.smsmode.com/settings/dev' target='_blank'
            rel='noopener noreferrer'>here</a>. If you want your server to be notified when a reply is
            received by our system you need to setup a callback URL in your
            channel settings on <a href='https://ui.smsmode.com/settings/dev' target='_blank'
            rel='noopener noreferrer'>smsmode platform</a>.`}
          </LinkParagraph>
          <Important text="Replies are only available in France."></Important>
          <StaticTable
            title="PARAMETERS"
            data={[
              {
                numero: `Recipient MSISDN (international format of type 336xxxxxxxx)`,
                date_reception: `Status report delivery date (date format is yyyy-MM-dd HH:mm:ss)`,
                statut: `Cf. status list`,
                smsID: `SMS Message ID`,
                refClient: `Optional reference ID if it has been set when the SMS message has been sent`,
                cost: `Cost of the SMS sending in credit`,
                mcc_mnc: `Operator code (format to GSM standard)`
              }
            ]}
          />
          <OverrideResponseWrapper>
            <ResponseContainer>
              <CopyCode label="Request example">
                {`http://www.votreServeur.com/notificationReponse.php?numero=36034&message=bonjour&emetteur=
            33601020304&date_reception=01012013-122233&smsID=12345678&refClient=ref123&
            responseID=azerty123`}
              </CopyCode>
            </ResponseContainer>
          </OverrideResponseWrapper>
        </Section>
        <Section id={sections[13]}>
          <Title>
            <Number>14</Number>
            {sections[13]}
          </Title>
          <Paragraph>
            The HTTP API allows you to fetch SMS message replies received. The
            list can be fetched using two different methods:
            <br />• by pagination starting by the most recent reply (0 to 49, 50
            to 99,…),
            <br />• by date by specifying a time interval (all replies received
            between 2014-01-01 and 2014-02-01). By default, the replies will be
            returned by pagination.
          </Paragraph>
          <BaseUrl>https://api.smsmode.com/http/1.6/responseList.do</BaseUrl>
          <OverrideResponseWrapper>
            <ResponseContainer>
              <CopyCode label="Request example">
                {`https://api.smsmode.com/http/1.6/responseList.do?accessToken=Ab1CD2efg3Hi`}
              </CopyCode>
            </ResponseContainer>
          </OverrideResponseWrapper>
          <StaticTable
            title="AUTHENTICATION PARAMETER"
            data={[
              {
                accessToken: `Access key to be used to connect to your smsmode<sup>©</sup> channel`
              }
            ]}
          />
          <StaticTable
            title="OPTIONAL PARAMETERS"
            data={[
              {
                start: `Index of the first reply of the list (default value is 0). This parameter cannot be used with “startDate” and “endDate” parameters.`,
                offset: `Desired page size (default value is 50). This parameter cannot be used with “startDate” and “endDate” parameters.`,
                startDate: `Start date of the replies search. Must follow this format ddMMyyyy-HH:mm. If this parameter is set, “endDate” parameter must also be set.`,
                endDate: `End date of the replies search. Must follow this format ddMMyyyy-HH:mm. OIf this parameter is set, “startDate” parameter must also be set.`
              }
            ]}
          />

          <OverrideResponseWrapper>
            <ResponseContainer>
              <CopyCode label="Request example">
                {`https://api.smsmode.com/http/1.6/responseList.do?accessToken=Ab1CD2efg3Hi&start=50&offset=150 (Request paginated with responses sent from 50 to 199)\nhttps://api.smsmode.com/http/1.6/responseList.do?accessToken=Ab1CD2efg3Hi&startDate=01012014-00:00&endDate=01022014-00:00 (Request per date including answers from 01/01/2014 to 01/02/2014)`}
              </CopyCode>
            </ResponseContainer>
          </OverrideResponseWrapper>

          <StaticTable
            title="RESPONSE"
            data={[
              {
                response_ID: `Reply ID`,
                reception_date: `Reception date (formatted as ddMMyyyy-HH:mm)`,
                from: `Reply sender`,
                text: `Reply text`,
                to: `Phone number or short code on which the reply has been received`,
                message_ID: `ID of the SMS message to which the reply has been linked`
              }
            ]}
          />
          <Important text="Response to the HTTP request will follow this pattern : response_ID | reception_date | from | text | to | message_ID"></Important>
          <StaticTable
            customColumn1="STATUS CODE"
            data={[
              {
                32: `Authentication error`,
                35: `Invalid parameters (“accessToken” is mandatory)`,
                42: `Unauthorized ip address`
              }
            ]}
          />
        </Section>
        <Section id={sections[14]}>
          <Title>
            <Number>15</Number>
            {sections[14]}
          </Title>
          <Paragraph>
            The HTTP API allows you to send Text-To-Speech messages (using a
            voice synthesis system). Messages can be sent immediately or
            scheduled. The message text must be written phonetically for a
            better elocution of the message. We strongly advise you to make some
            tests in order to verify that the chosen text in intelligible.
          </Paragraph>
          <BaseUrl>
            https://api.smsmode.com/http/1.6/sendVoiceMessage.do
          </BaseUrl>

          <OverrideResponseWrapper>
            <ResponseContainer>
              <CopyCode label="Request example">
                {`https://api.smsmode.com/http/1.6/sendVoiceMessage.do?accessToken=Ab1CD2efg3Hi&message=Hello+Mum&numero=+336xxxxxxxx&title=the+title`}
              </CopyCode>
            </ResponseContainer>
          </OverrideResponseWrapper>
          <Important text="Please note: Do not forget to add + before the phone number."></Important>
          <StaticTable
            title="AUTHENTICATION PARAMETER"
            data={[
              {
                accessToken: `Access key to be used to connect to your smsmode<sup>©</sup> channel`
              }
            ]}
          />
          <StaticTable
            title="MANDATORY PARAMETERS"
            data={[
              {
                message: `Message text (max length is 160 characters)`,
                numero: `Comma separated MSISDN list. These numbers may use national format (06xxxxxxxx –
                  only for French MSISDN) or international format (336xxxxxxxx).
                  When using HTTP GET list size is limited to 300.`
              }
            ]}
          />
          <StaticTable
            title="OPTIONAL PARAMETERS"
            data={[
              {
                title: `Title of the message that will be read before the text (for example ‘Message from the Company …’ - Default title is ‘This is a message from smsmode<sup>©</sup> ’).`,
                date_envoi: `Sent date of the message formatted as ddMMyyyy-HH:mm.`,
                language: `Language of de voice message. This parameter accepts the folowing values fr-FR, en-GB, de-DE, es-ES.`
              }
            ]}
          />
          <StaticTable
            title="RESPONSE"
            data={[
              {
                0: `Accepted – message is accepted by the system and is currently being processed`,
                31: `Internal error`,
                32: `Authentication error`,
                33: `Insufficient organisation balance`,
                35: `Missing mandatory parameter`,
                50: `Temporarily unavailable`
              }
            ]}
          />
          <Important text="Response to the request will follow this pattern: status _code | description | smsID (present if status_code is 0)"></Important>
        </Section>
        <Section id={sections[15]}>
          <Title>
            <Number>16</Number>
            {sections[15]}
          </Title>
          <Paragraph>
            The HTTP API allows you to automatize Unicode SMS (message
            containing non-latin characters, technical characters or emoticons).
            The Unicode messages can be sent immediately or scheduled for later.
            HTTP <span>GET</span> and <span>POST methods</span> are supported
            but only POST method allows to send SMS messages to more than 300
            recipients using only one request.
          </Paragraph>
          <SubTitle>UNICODE SMS FEATURES AND CHARACTERS COUNT</SubTitle>
          <Paragraph>
            A Unicode message can contain a maximum of 70 characters (instead of
            160 for a regular SMS). A maximum of 5 messages can be concatened to
            send messages longer than 70 characters as a single message
          </Paragraph>
          <StaticTable
            title="RESPONSE"
            data={[
              {
                1: `70 characters`,
                2: `134 characters`,
                3: `201 characters`,
                4: `268 characters`,
                5: `335 characters`
              }
            ]}
          />
          <Important
            text="Certain Unicode characters count double.<br/>
              • The Unicode characters U+0000 to U+FFFF count 1 character <a href='https://fr.wikipedia.org/wiki/Table_des_
              caract%C3%A8res_Unicode_(0000-FFFF)' target='_blank'
              rel='noopener noreferrer'>(more info here).</a><br/>
              • The other characters count double."
          />
          <BaseUrl>https://api.smsmode.com/http:1.6/sendSMS.do</BaseUrl>

          <OverrideResponseWrapper>
            <ResponseContainer>
              <CopyCode label="Request example">
                {`https://api.smsmode.com/http/1.6/sendSMS.do?accessToken=Ab1CD2efg3Hi&classe_msg=9&message=%E2%98%BA&emetteur=PERSO&numero=336xxxxxxxx,06yyyyyyyy`}
              </CopyCode>
            </ResponseContainer>
          </OverrideResponseWrapper>

          <Paragraph>
            In this example, an SMS is going to be sent to +3363123456 and
            062312345, with the sender ID “PERSO” and the message containing
            “☺”.
          </Paragraph>
          <StaticTable
            title="AUTHENTICATION PARAMETER"
            data={[
              {
                accessToken: `Access key to be used to connect to your smsmode<sup>©</sup> channel`
              }
            ]}
          />
          <StaticTable
            title="MANDATORY PARAMETERS"
            data={[
              {
                message: `SMS text. It must be encoded in UTF-8 characters and the maximum text length for 1 SMS
                is 160 characters.
                If you need to send long SMS (more than 160 characters) please refer to optional
                parameters below.
                The UTF-8 is different to the ISO-8859-1 used for sending regular SMS.`,
                classe_msg: `Must have a value of 9.`,
                "numero **": `Comma separated MSISDN list. These numbers may use national format (06xxxxxxxx –
                  only for French MSISDN) or international format (336xxxxxxxx).
                  When using HTTP GET list size is limited to 300.`,
                "groupe **": `Name of the group of contact which contains the recipients’ MSISDNs. Note that the group
                must be present (previously created) on your smsmode<sup>©</sup> organisation.`
              }
            ]}
          />
          <Important text="** Use only one of the two parameters"></Important>
          <StaticTable
            title="OPTIONAL PARAMETERS"
            data={[
              {
                date_envoi: `SMS sending date (format : ddMMyyyy-HH:mm)`,
                refClient: `Optional reference ID that will be sent along with the delivery report callback. May be used
                to set a foreign ID (coming from your system) on this SMS message.
                Maximum size is 140 characters.`,
                emetteur: `Text to be used to customize the SMS sender ID.
                MSISDN are not allowed.
                Maximum size is 11 characters without blank space or accent.
                Sender ID available according to the technical constraints of the countries.`,
                nbr_msg: `Max number of SMS messages that can be sent (default to 5)
                The message will be truncated if it exceeds this limit. Depending on the desired
                characters count, indicate the number of message to be used as following:<br />
                • nbr_msg=1 : 70 characters max<br />
                • nbr_msg=2 : 134 characters max<br />
                • nbr_msg=3 : 201 characters max<br />
                Certain characters count double (cf. explications above)`,
                notification_url: `Callback URL to be used to notify your system when delivery report is updated.`,
                notification_url_reponse: `Callback URL to be used to notify your system when incoming message arrives.`
              }
            ]}
          />
        </Section>
        <Section id={sections[16]}>
          <Title>
            <Number>17</Number>
            {sections[16]}
          </Title>
          <Paragraph>
            This request allow to send SMS messages to multiple recipients and
            personalize the message for each recipient. So, instead of sending
            hundreds of requests, you can carry out only one request by joining
            a personalization file containing the recipients numbers and
            personalized elements.
          </Paragraph>
          <BaseUrl>https://api.smsmode.com/http/1.6/sendSMSBatch.do</BaseUrl>
          <OverrideResponseWrapper>
            <ResponseContainer>
              <CopyCode label="Request example">
                {`curl -v -k -H “ContentType: multipart/formdata” -X POST -F\n
            “fichier=@fichier.csv;type=- text/csv”\n
            “https://api.smsmode.com/http/1.6/sendSMSBatch.do?accessToken=Ab1CD2efg3Hi”`}
              </CopyCode>
            </ResponseContainer>
          </OverrideResponseWrapper>

          <StaticTable
            title="AUTHENTICATION PARAMETER"
            data={[
              {
                accessToken: `Access key to be used to connect to your smsmode<sup>©</sup> channel`
              }
            ]}
          />
          <StaticTable
            title="OPTIONAL PARAMETERS"
            data={[
              {
                classe_msg: `• 2: Pro SMS (default)<br />
                • 4: SMS with answer authorized (only available in France)`,
                date_envoi: `SMS sending date (format : ddMMyyyy-HH:mm)`,
                refClient: `Optional client data sent with the automatic return notification. Can be used for “tag” the
                request with your own identification.
                Maximum size is 140 characters.`,
                emetteur: `Text to use to personalize the sender ID.
                Phone numbers not authorized.
                11 characters without spances and accents. Can be udes if classe_msg=4
                Sender ID available according to the technical constraints of the countries.`,
                nbr_msg: `Max number of SMS messages that can be sent (default to 5)
                The message will be truncated if it exceeds this limit. Depending on the desired
                characters count, indicate the number of message to be used as following:<br />
                • nbr_msg=1 : 160 characters max<br />
                • nbr_msg=2 : 306 characters max<br />
                • nbr_msg=3 : 459 characters max<br />`,
                notification_url: `Callback URL to be used to notify your system when delivery report is updated.`,
                notification_url_reponse: `Callback URL to be used to notify your system when incoming message arrives.`
              }
            ]}
          />
          <StaticTable
            title="RESPONSE"
            data={[
              {
                0: `Accepted - the message has been accepted by the system and is being processed`,
                31: `Internal error`,
                32: `Authentication error`,
                33: `Insufficient credit`,
                35: `Mandatory parameters missing`,
                50: `Temporarily unaccessible`
              }
            ]}
          />
          <SubTitle>ENCODING AND CHARACTERS</SubTitle>
          <ScrollButton onClick={() => scrollToTop("CHARACTER ENCODING", -100)}>
            See character encoding
          </ScrollButton>

          <SubTitle>ATTACHED FILE</SubTitle>
          <Paragraph>
            In the previous example, the attached file can be under the
            following format:
          </Paragraph>
          <OverrideResponseWrapper>
            <ResponseContainer>
              <CopyCode label="CSV file">
                {` "numero","message","emetteur","refClient"\n"999999999","This is the body of the message 1","RDV","ref123"\n"888888888","This is the body of the message 2","","re456"\n"777777777","This is the body of the message 3","","ref789"\n`}
              </CopyCode>
            </ResponseContainer>
          </OverrideResponseWrapper>
          <Paragraph>
            The request defined this way will allow to send 3 messages with a
            specific text to each recipient.
          </Paragraph>
          <Important text="The name of the file is not taken in account by the system. The file encoding should be under ISO-8859-15 format."></Important>
        </Section>
        <Section id={sections[17]}>
          <Title>
            <Number>18</Number>
            {sections[17]}
          </Title>
          <StaticTable
            data={[
              {
                0: `Sent`,
                1: `In progress`,
                2: `Internal error`,
                10: `Programmed`,
                11: `Received`,
                12: `Partially delivered`,
                13: `Issued operator (temporary status)`,
                14: `Issued`,
                15: `Partially received`,
                16: `Listened`,
                21: `Not deliverable`,
                22: `Rejected`,
                33: `Not sent - insufficient credit`,
                34: `Routing error`,
                35: `Reception error`,
                36: `Message error`,
                37: `Expired message`,
                38: `Message too long`,
                50: `Not delivered`,
                40: `Model`,
                100: `Read`,
                101: `Not read`,
                999: `Undefined`,
                3501: `Temporary operator error`,
                3502: `Temporary absence error`,
                3503: `Temporary phone error`,
                3504: `Temporary portability error`,
                3521: `Permanent operator error`,
                3522: `Permanent absence error`,
                3523: `Permanent phone error`,
                3524: `Permanent anti-spam error`,
                3525: `Permanent content error`,
                3526: `Permanent portability error`,
                3527: `Permanent roaming error`,
                3560: `Non-routable error`,
                3599: `Other error`,
                3998: `Invalid recipient`,
                3999: `Blacklisted recipient`
              }
            ]}
          />
        </Section>
        <Deprecated>DEPRECATED</Deprecated>
        <BackToTop id="back2TopButton" onClick={() => scrollToTop("", -100)}>
          <UpArrow />
        </BackToTop>
      </RightContainer>
    </Wrapper>
  );
};

const fade = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const ScrollButton = styled.button`
  text-decoration: underline;
  cursor: pointer;
  color: #52b474;
  background: none;
  border: none;
  outline: none;
  font-size: 14px;
`;

const ButtonSnippets = styled.button`
transition: ease 0.275s;
  animation: ${fade} 0.7s;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  margin-bottom: 50px;
  font-weight: 500px;
  font-size: 20px;
  background-color: ${({ theme }) => `${theme.second}`};
  color: ${({ theme }) => `${theme.text}`};
  padding: 5px 15px;
  border-radius: 5px;
  &:hover {
    transform translateY(-.1em);
    box-shadow: 0 5px 20px rgb(0 0 0 / 10%);
  }
`;

const BackToTop = styled.button`
  animation: ${fade} 0.7s;
  display: none;
  border: none;
  transition: ease 0.275s;
  &:hover {
    transform translateY(-.1em);
    box-shadow: 0 5px 20px rgb(0 0 0 / 10%);
  }
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => `${theme.second}`};
  border-radius: 50%;
  height: 70px;
  min-width: 70px;
  cursor: pointer;
  outline: none;
  position: fixed;
  right: 100px;
  bottom: 100px;
  z-index: 10;
  width: 50px;
  & > svg {
    height: 30px;
    width: 30px;
    transform: rotate(180deg);
  }
  @media (max-width: 900px) {
    right: 50px;
    bottom: 50px;
  }
`;

const SvgWrapper = styled.div`
  & > svg {
    transform: rotate(10deg);
    height: 100%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  min-height: 1000px;
`;

const LeftContainer = styled.div`
  position: fixed;
  width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  @media (max-width: 900px) {
    display: none;
  }
`;

const RightContainer = styled.div`
  @media (max-width: 900px) {
    margin-left: 0;
    padding: 0 0 0 20px;
    width: calc(100% - 20px);
  }
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 320px;
`;

const Section = styled.div`
  max-width: 1232px;
  margin-bottom: 100px;
  @media (max-width: 1300px) {
    margin-left: 10px;
  }
  @media (max-width: 900px) {
    margin-left: 0;
  }
`;

const CharacterEncodingContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 830px) {
    justify-content: center;
  }
`;

const TitleContainer = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > h1 {
    font-size: 35px;
    font-weight: 500;
  }
  & > h2 {
    margin: 10px 0;
    font-size: 16px;
  }
`;

const Number = styled.div`
  background-color: #52b4743d;
  border-radius: 5px;
  margin-left: 10px;
  height: 25px;
  max-width: 25px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  font-size: 16px;
  margin-right: 15px;
`;

export default ApiHttp;
