import React from "react";
import styled from "styled-components";

import { Table } from "antd";

const StaticTable = ({
  title,
  desc,
  data,
  customColumn1,
  customColumn2,
  borderRight
}) => {
  const columns = [
    {
      title: customColumn1 ? customColumn1 : "PARAMETER",
      dataIndex: "parameter",
      key: "parameter"
    },
    {
      title: customColumn2 ? customColumn2 : "DESCRIPTION",
      dataIndex: "description",
      key: "description",
      render: (html) => {
        return <Container dangerouslySetInnerHTML={{ __html: html }} />;
      }
    }
  ];

  const dataSource = data.map((item, index) =>
    Object.entries(item).map((e) => ({
      key: index + e[0],
      parameter: e[0],
      description: e[1]
    }))
  );

  return (
    <Wrapper borderRight={borderRight}>
      {title && <h2 dangerouslySetInnerHTML={{ __html: title }} />}
      {desc && <p>{desc}</p>}
      <Table
        columns={columns}
        dataSource={dataSource.flat()}
        pagination={false}
      />
    </Wrapper>
  );
};

const Container = styled.div`
  & > a {
    color: rgb(82, 180, 116);
  }
`;

const Wrapper = styled.div`
  margin: 60px 0px 20px;
  max-width: 850px;
  height: fit-content;
  border-right: ${({ borderRight, theme }) => borderRight && theme.border};
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    border-bottom: ${({ theme }) => theme.border} !important;
  }

  .ant-table-cell,
  .ant-table {
    color: ${({ theme }) => theme.text} !important;
  }

  & > h2 {
    font-weight: bold;
    text-align: end;
    font-size: 18px;
    margin-bottom: 10px;
  }
  & > p {
    margin: 10px 0;
    font-size: 14px;
    text-align: end;
    font-style: italic;
  }

  @media (max-width: 575px) {
    border-right: none;
  }
`;

export default StaticTable;
