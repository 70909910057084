import styled from "styled-components";

export const Deprecated = styled.div`
  padding: 8px;
  border: none;
  outline: none;
  position: fixed;
  right: 50px;
  top: 80px;
  border-radius: 5px;
  background-color: ${({ theme }) => `${theme.second}`};
  color: #fd6565;
`;
